import React, {useCallback, useState} from 'react';
import {Button, Col, FormControl, Row} from 'react-bootstrap';
import DateShort from '../elements/DateTime/DateShort';
import PremiumAmount from '../elements/Values/PremiumAmount';
import DetailsIcon from '../elements/Icons/DetailsIcon';
import VoucherPrintButton from '../elements/PrintButtons/VoucherPrintButton';
import GreenCardPrintButton from '../elements/PrintButtons/GreenCardPrintButton';
import PropTypes from 'prop-types';
import StickerInfoButton from './NavigationButtons/StickerInfoButton';
import GreenCardInfoButton from './NavigationButtons/GreenCardInfoButton';
import {
    useGetCurrentUserQuery,
    useSendVoucherEmailMutation, useSetOffForDamageVoucherMutation,
    useUpdateVoucherDetailsMutation,
    useUpdateVoucherMutation,
    useVoidVoucherMutation
} from '../../features/apiSlice';
import ErrorWidget from './ErrorWidget';
import RequireRole from '../elements/AccessControl/RequireRole';
import SwitchButton from '../elements/Form/SwitchButton';
import {paymentMethods, voucherTypes} from '../../utils.js';
import VoucherEmailButton from '../elements/Buttons/VoucherEmailButton.jsx';
import useBrokerVoucherCommission from '../../hooks/useBrokerVoucherCommission.js';
import Percent from '../elements/Values/Percent.jsx';
import AgentCommissionListWidget from './AgentCommissionListWidget.jsx';
import TextWithCopyButton from './TextWithCopyButton.jsx';
import VoucherStickerWidget from "./VoucherStickerWidget.jsx";
import VoucherGreenCardWidget from "./VoucherGreenCardWidget.jsx";
import Card from "../elements/ReactBootstrap/Card.jsx";
import useCurrentUserHasRole from "../../hooks/useCurrentUserHasRole.js";
import EditButton from "./SimpleButtons/EditButton.jsx";
import SaveButton from "./SimpleButtons/SaveButton.jsx";
import CloseButton from "./SimpleButtons/CloseButton.jsx";
import VoucherIssueUserWidget from "./VoucherIssueUserWidget.jsx";
import VoucherCommissionUserWidget from "./VoucherCommissionUserWidget.jsx";
import VoucherPaymentMethodWidget from "./VoucherPaymentMethodWidget.jsx";

const VouchersCardWidget = ({
    policy,
    vouchers,
    printing,
    setPrinting,
}) => {
    const [sending, setSending] = useState(false);
    const [newNumber, setNewNumber] = useState('');
    const [newDate, setNewDate] = useState('');
    const [editableVoucherId, setEditableVoucherId] = useState('');
    const [editVoucherData, setEditVoucherData] = useState(false);
    const [editVoucherPublisher, setEditVoucherPublisher] = useState(false);
    const [editVoucherCommissionUser, setEditVoucherCommissionUser] = useState(false);
    const [editPaymentMethod, setEditPaymentMethod] = useState(false);
    const hasRoleBrokerUserManager = useCurrentUserHasRole('ROLE_BROKER_USER_MANAGER');
    const hasRoleUpdateVoucher = useCurrentUserHasRole('ROLE_UPDATE_VOUCHER');
    const hasRoleHideUserCommission = useCurrentUserHasRole('ROLE_HIDE_USER_COMMISSION') ;

    const [send] = useSendVoucherEmailMutation();

    const [update, {
        isError: updateIsError,
        error: updateError,
    }] = useUpdateVoucherMutation();

    const [voidVoucher, {
        isError: voidIsError,
        error: voidError,
    }] = useVoidVoucherMutation();

    const [setOffForDamageVoucher, {
        isError: setOffForDamageIsError,
        error: setOffForDamageError,
    }] = useSetOffForDamageVoucherMutation();

    const [updateDetails, {
        isError: updateDetailsIsError,
        error: updateDetailsError,
    }] = useUpdateVoucherDetailsMutation();

    const getFirstInstallment = useCallback(voucher => voucher.installments.concat().shift(), []);
    const getPolicy = useCallback(voucher => getFirstInstallment(voucher).policy, [getFirstInstallment]);

    const {
        data: currentUser,
    } = useGetCurrentUserQuery();

    const {
        getBrokerVoucherCommissionCoefficient,
        getBrokerVoucherCommissionAmount,
    } = useBrokerVoucherCommission(currentUser);

    if (!policy) {
        return null;
    }

    const hasTax = policy.product.insuranceType !== 'life';
    const hasMtplCover = policy.product.hasMtplCover;
    // TODO ugly workaround
    const hasCascoCover = policy.product.insuranceType === 'casco';
    const hasGf = hasMtplCover || policy.product.hasPassengersAccidentCover;

    return (<div>
        {vouchers.map(voucher =>
            <Card noPadding baseClassName={voucher.void ? 'progress-bar-striped grid-row-danger' : voucher.setOfForDamage ? 'progress-bar-striped grid-row-secondary' : null}
                  key={voucher.id} isSimpleHeader={false} header={
                <Row>
                    <Col className='d-flex justify-content-start'>
                        <h6>{voucher.void ? 'Анулирана ': ''}{voucherTypes[voucher.type]}</h6>
                    </Col>
                    <Col className='d-flex justify-content-end'>
                        {(!voucher.number || !voucher.issueDate) && hasRoleUpdateVoucher && <>
                            <EditButton onClick={() => {
                                setEditVoucherData(oldState => !oldState);
                                setEditableVoucherId(voucher.id);
                            }}/>
                            {editVoucherData && editableVoucherId === voucher.id && <SaveButton onClick={() => {
                                let updatableData = {};
                                if (newNumber !== '') updatableData.number = newNumber;
                                if (newDate !== '') updatableData.issueDate = newDate;
                                update([voucher.id, updatableData]);
                                setEditVoucherData(oldState => !oldState);
                                setNewNumber('');
                                setNewDate('');
                                setEditableVoucherId('');
                            }} />}
                            {editVoucherData && editableVoucherId === voucher.id && <CloseButton onClick={() => {
                                setEditVoucherData(oldState => !oldState);
                                setNewNumber('');
                                setNewDate('');
                                setEditableVoucherId('');
                            }} />}
                        </>
                        }
                    </Col>
                </Row>}>
                <Row className="gx-4 text-center ms-0 me-0 border-bottom pt-3">
                    <Col className="fw-bold">№</Col>
                    <Col className="fw-bold">Дата</Col>
                    <Col className="fw-bold">Вноска</Col>
                    <Col className="fw-bold d-none d-lg-block">Премия</Col>
                    {hasTax && <Col className="fw-bold d-none d-lg-block">Данък</Col>}
                    {hasMtplCover && <Col className="fw-bold d-none d-lg-block">Стикер</Col>}
                    {hasGf && <Col className="fw-bold d-none d-lg-block">ГФ/ОФ</Col>}
                    <Col className="fw-bold">Сума</Col>
                    {voucher.correctionAmount > 0 && <Col className="fw-bold d-none d-lg-block">Кор.</Col>}
                    <Col className="fw-bold">Печат</Col>
                    <Col className="fw-bold"></Col>
                    <Col className="fw-bold">Мейл</Col>
                </Row>
                <Row  className="gx-4 text-center ms-0 me-0 border-bottom pt-3">
                    <Col>
                        {voucher.number}
                        {(!voucher.number && editVoucherData && editableVoucherId === voucher.id) &&
                            <FormControl type="text" value={newNumber}
                                         onChange={e=> setNewNumber(e.target.value)}/>
                        }
                    </Col>
                    <Col><DateShort date={voucher.issueDate} html />
                        {(!voucher.issueDate && editVoucherData && editableVoucherId === voucher.id) &&
                            <FormControl type="date" value={newDate}
                                         onChange={e=> setNewDate(e.target.value)}/>
                        }
                    </Col>
                    <Col>{(voucher.installments || []).map(installment => installment.number).join(',')} /{' '}
                        {getPolicy(voucher).installmentsCount || ''}</Col>
                    <Col className="d-none d-lg-block">
                        <PremiumAmount amount={voucher.premiumAmount} />{' '}
                        (<PremiumAmount amount={voucher.premiumAmountCurrency} currency='EUR'/>)
                    </Col>
                    {hasTax && <Col className="d-none d-lg-block">
                        <PremiumAmount amount={voucher.taxAmount} />{' '}
                        (<PremiumAmount amount={voucher.taxAmountCurrency} currency='EUR' />)
                    </Col>}
                    {hasMtplCover && <Col className="d-none d-lg-block">
                        <PremiumAmount amount={voucher.stickerAmount} />{' '}
                        (<PremiumAmount amount={voucher.stickerAmountCurrency} currency='EUR' />)
                    </Col>}
                    {hasGf && <Col className="d-none d-lg-block">
                        <PremiumAmount amount={voucher.guaranteeFundAmount} />{' '}
                        (<PremiumAmount amount={voucher.guaranteeFundAmountCurrency} currency='EUR' />)
                    </Col>}
                    <Col><PremiumAmount amount={voucher.totalAmount} />{' '}
                        (<PremiumAmount amount={voucher.totalAmountCurrency} currency='EUR' />){' '}
                        <DetailsIcon details={voucher.details} /></Col>
                    {voucher.correctionAmount > 0 && <Col className="d-none d-lg-block"><PremiumAmount amount={voucher.correctionAmount} /></Col>}
                    <Col className="pb-3">
                        <VoucherPrintButton voucher={voucher} label="" printing={false}
                                            disabled={printing} setDisabled={setPrinting} small/>
                    </Col>
                    <Col>
                    {voucher.greenCard && <GreenCardPrintButton greenCard={voucher.greenCard}
                          label="" printing={false} disabled={printing} setDisabled={setPrinting} small/>}
                    </Col>
                    <Col><VoucherEmailButton onClick={() => send(voucher.id)} disabled={sending}
                                             setDisabled={setSending} label="" small/></Col>
                </Row>
                <Row className="ms-0 me-0">
                    <Col className="ms-3 mt-3 mb-3">
                        {(voucher.sticker === undefined || voucher.sticker === null) && hasMtplCover && policy.updatable
                            && voucher.issueUser && (voucher.type === 'STANDARD' || voucher.type === 'ANNEX_STICKER') &&
                            hasRoleUpdateVoucher &&
                            <div>Стикер:
                                <VoucherStickerWidget insuranceCompanyId={policy.insuranceCompany.id}
                                    office={voucher.issueOffice.id}
                                    voucherId={voucher.id}
                                />
                            </div>
                        }
                        {voucher.sticker && <div className="mb-2">Стикер: <StickerInfoButton sticker={voucher.sticker} /></div>}
                    {(voucher.greenCard === undefined || voucher.greenCard === null) && hasMtplCover && voucher.hasGreenCard
                        && voucher.issueUser && (voucher.type === 'STANDARD' || voucher.type === 'ANNEX_GRC')
                        && policy.updatable && hasRoleUpdateVoucher &&
                        <div>СЗК:
                            <VoucherGreenCardWidget insuranceCompanyId={policy.insuranceCompany.id}
                                office={voucher.issueOffice.id}
                                voucherId={voucher.id}
                            />
                        </div>
                    }
                    {voucher.greenCard && <div className="mb-2">
                        СЗК: <GreenCardInfoButton greenCard={voucher.greenCard} />
                        {voucher.greenCard.policyNumber && <>{' '}
                            <GreenCardInfoButton greenCard={voucher.greenCard} textField="policyNumber" />
                        </>}
                    </div>}
                        {voucher.type === 'STANDARD' && hasMtplCover &&
                            (voucher.greenCard === undefined || voucher.greenCard === null)
                            && hasRoleUpdateVoucher &&
                            <div className="mt-3">
                            По квитанцията има СЗК:{' '}
                            <SwitchButton
                                checked={voucher.hasGreenCard}
                                onChange={checked => update([voucher.id, {
                                    hasGreenCard: checked,
                                }])}
                            /></div>}
                        {voucher.type === 'STANDARD' && hasCascoCover && <div className="mt-3">
                            Прихващане по щета:{' '} {voucher.setOfForDamage ? 'да' :
                            hasRoleUpdateVoucher ? <SwitchButton checked={voucher.setOfForDamage}
                                         onChange={() => setOffForDamageVoucher(voucher.id)} /> : 'не' }
                        </div>}
                    <div>
                        Показване в предстоящи плащания: <SwitchButton
                        checked={voucher.notifyUser}
                        onChange={checked => update([voucher.id, {
                            notifyUser: checked,
                        }])}
                    />
                    </div>
                        <RequireRole name="ROLE_UPDATE_VOUCHER">
                            {voucher.void ? '': <div>
                                Анулиранe <SwitchButton checked={voucher.void} onChange={() => voidVoucher(voucher.id)}/>
                            </div>}
                        </RequireRole>
                        {!voucher.issueUser && hasRoleUpdateVoucher && <>
                            <Button variant="outline-secondary" className="mb-3 mt-3" onClick={() => {
                                setEditVoucherPublisher(oldState => !oldState);
                                setEditableVoucherId(voucher.id);
                            }}>
                                <i className="fas fa-user-tag" /> Издал документа
                            </Button>
                            {editVoucherPublisher && editableVoucherId === voucher.id &&
                                <VoucherIssueUserWidget voucherId={voucher.id} />
                            }
                        </>}
                </Col>
                <Col className="mt-3 mb-3">
                    <Row className="mb-3">
                        <Col sm={8}>Начин на плащане: {paymentMethods[voucher.paymentMethod]}
                            {voucher.paymentMethod === 'POS_TERMINAL' && voucher.posTerminalReference && <>
                                , код: <TextWithCopyButton text={voucher.posTerminalReference} />
                            </>}</Col>
                        <Col sm={4}>{hasRoleUpdateVoucher && <Button title="Промяна начин на плащане" size='sm'
                                                              variant="outline-secondary" onClick={() => {
                            setEditPaymentMethod(oldState => !oldState);
                            setEditableVoucherId(voucher.id);
                        }}><i className="fas fa-cash-register" /></Button>}</Col>
                    </Row>
                    {editPaymentMethod && <Row><VoucherPaymentMethodWidget
                        onSubmit={values => {
                            update([voucher.id, {paymentMethod: values.paymentMethod}]);
                            setEditPaymentMethod(oldState => !oldState);
                        }}/></Row>}
                    {voucher.issueUser && (voucher.issueUser?.id === currentUser.id ||
                            voucher.commissionUser?.id === currentUser.id || hasRoleBrokerUserManager) ?
                        <Row className="mb-3">
                            <Col sm={8}>Консултант: {voucher.issueUser?.shortName} ({voucher.issueOffice?.name})</Col>
                            {!voucher.commissionUser && hasRoleBrokerUserManager && <Col sm={4}>
                                <Button title="Добавяне на получаващ комисион" size='sm'
                                        variant="outline-secondary" onClick={() => {
                                    setEditVoucherCommissionUser(oldState => !oldState);
                                    setEditableVoucherId(voucher.id);
                                }}><i className="fas fa-people-arrows" /></Button>
                            </Col>}
                        </Row> :
                        <Row>{voucher.issueInsuranceBroker ?
                            <>Брокер: {voucher.issueInsuranceBroker?.shortName}</> : 'Не е попълнен брокер' }
                        </Row>
                    }
                    {editVoucherCommissionUser && editableVoucherId === voucher.id && <Row>
                        <VoucherCommissionUserWidget voucherId={voucher.id} setEdit={setEditVoucherCommissionUser}/>
                    </Row>}

                    {voucher.commissionUser && (voucher.issueUser?.id === currentUser.id
                        || voucher.commissionUser?.id === currentUser.id
                        || hasRoleBrokerUserManager) && <div>
                        Комисион се изплаща на: {voucher.commissionUser.shortName}
                    </div>}
                    {voucher.premiumAmount > 0 && <RequireRole name="ROLE_SHOW_COMMISSION">
                        <Row>
                            <Col sm={8}>
                                Комисион брокер:{' '}
                                <PremiumAmount amount={getBrokerVoucherCommissionAmount(voucher, policy)} />{' '}
                                (<Percent coefficient={getBrokerVoucherCommissionCoefficient(voucher, policy)} />)
                            </Col>
                            {policy.updatable && hasRoleUpdateVoucher && <Col sm={4}>
                                <Button variant="outline-secondary" size='sm' title="Обновяване на комисиони"
                                        className="m-2" onClick={() => updateDetails(voucher.id)}>
                                    <i className="fas fa-retweet" />
                                </Button>
                            </Col>}
                        </Row>
                    </RequireRole>}
                    {(voucher.issueUser?.id === currentUser.id ||
                            policy.issueUser?.id === currentUser.id ||
                            voucher.commissionUser?.id === currentUser.id  ||
                            policy.commissionUser?.id === currentUser.id ||
                            hasRoleBrokerUserManager) && !hasRoleHideUserCommission  &&
                        <AgentCommissionListWidget voucher={voucher} policy={policy}/>}
                </Col>
                    {voucher.issueInsuranceBroker && <Col className="ms-3 mt-3 mb-3">
                        {voucher.paymentMethod !== 'BANK_ACCOUNT_INSURANCE_COMPANY' &&
                        <div>Платена към брокер:{' '}{voucher.paidPremiumFromClient ? 'да / ' : 'не'}
                            <DateShort date={voucher.paidPremiumFromClientDate} html />
                        </div>}
                        {voucher.paymentMethod !== 'BANK_ACCOUNT_INSURANCE_COMPANY' ?
                        <div>Платена към ЗК:{' '}{voucher.paidPremiumToInsuranceCompany ? 'да / ' : 'не'}
                            <DateShort date={voucher.paidPremiumToInsuranceCompanyDate} html />
                        </div> :
                        <div>Платена от клиент в ЗК:{' '}{voucher.paidPremiumToInsuranceCompany ? 'да / ' : 'не'}
                            <DateShort date={voucher.paidPremiumToInsuranceCompanyDate} html />
                        </div>
                        }
                        <div>Платен комисион на брокер:{' '}{voucher.paidPolicyCommissionFromInsuranceCompany ? 'да / ' : 'не'}
                            <DateShort date={voucher.paidCommissionFromInsuranceCompanyDate} html />
                        </div>
                        <div>Платен комисион на консултант:{' '}{voucher.paidPolicyCommissionToAgent ? 'да / ' : 'не'}
                            <DateShort date={voucher.paidCommissionToAgentDate} html />
                        </div>

                </Col>}
                    {(voidIsError || updateIsError || setOffForDamageIsError || updateDetailsIsError) && <Col>
                    {voidIsError && <ErrorWidget error={voidError} />}
                    {updateIsError && <ErrorWidget error={updateError} />}
                    {setOffForDamageIsError && <ErrorWidget error={setOffForDamageError} />}
                    {updateDetailsIsError && <ErrorWidget error={updateDetailsError} />}
                    </Col>}
            </Row>
            </Card>)}
    </div>);
};

VouchersCardWidget.propTypes = {
    policy: PropTypes.shape({
        product: PropTypes.shape({
            insuranceType: PropTypes.string.isRequired,
            hasMtplCover: PropTypes.bool.isRequired,
            hasPassengersAccidentCover: PropTypes.bool.isRequired,
        }).isRequired,
        updatable: PropTypes.bool,
        correctionCommissionCoefficient: PropTypes.number,
    }),
    vouchers: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        number: PropTypes.string,
        issueDate: PropTypes.string,
        issueOffice: PropTypes.shape,
        issueInsuranceBroker: PropTypes.shape,
        commissionUser: PropTypes.shape,
        premiumAmount: PropTypes.number.isRequired,
        taxAmount: PropTypes.number.isRequired,
        stickerAmount: PropTypes.number.isRequired,
        guaranteeFundAmount: PropTypes.number.isRequired,
        totalAmount: PropTypes.number.isRequired,
        correctionAmount: PropTypes.number,
        greenCard: PropTypes.object,
        paidPremiumFromClient: PropTypes.bool.isRequired,
        paidPremiumFromClientDate: PropTypes.string,
        paidPremiumToInsuranceCompany: PropTypes.bool.isRequired,
        paidPremiumToInsuranceCompanyDate: PropTypes.string,
        paidPolicyCommissionFromInsuranceCompany: PropTypes.bool.isRequired,
        paidCommissionFromInsuranceCompanyDate: PropTypes.string,
        paidPolicyCommissionToAgent: PropTypes.bool.isRequired,
        paidCommissionToAgentDate: PropTypes.string,
        setOfForDamage: PropTypes.bool,
    })).isRequired,
};

export default VouchersCardWidget;
