import PropTypes from 'prop-types';
import {useGetCurrentUserQuery} from "../../../features/apiSlice.js";

const PremiumAmount = ({
    amount,
    withCurrency,
    currency,
}) => {
    const {
        data: currentUser,
    } = useGetCurrentUserQuery();

    const isDemo = currentUser.insuranceBroker.demo;

    return (<span className={isDemo ? 'demo' : null}>{new Intl.NumberFormat('bg-BG', withCurrency ? {
        currency: currency,
        style: 'currency',
    } : {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(amount)}</span>)
};

PremiumAmount.defaultProps = {
    withCurrency: true,
    currency : 'BGN',
};

PremiumAmount.propTypes = {
    amount: PropTypes.number.isRequired,
    withCurrency: PropTypes.bool,
};

export default PremiumAmount;