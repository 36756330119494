import PropTypes from "prop-types";
import InfoIcon from "./InfoIcon";
import React from "react";
import BasicPremiumAmount from "../Values/BasicPremiumAmount.jsx";

const DetailsIcon = ({
    details,
}) => {
    if (!details || details.length < 2) {
        return null;
    }

    return (
        <InfoIcon onClick={() => alert(details.map(detail =>
            detail.description + ': ' + BasicPremiumAmount({
                amount: detail.totalAmount,
                withCurrency: BasicPremiumAmount.defaultProps.withCurrency, // manually calling function
                currency: 'BGN',
            }) + '(' + BasicPremiumAmount({
                    amount: detail.totalAmountCurrency,
                    withCurrency: BasicPremiumAmount.defaultProps.withCurrency, // manually calling function
                    currency: 'EUR',
                }) + ')'
        ).join('\n'))} />
    );
};

DetailsIcon.propTypes = {
    details: PropTypes.arrayOf(PropTypes.shape({
        description: PropTypes.string.isRequired,
        totalAmount: PropTypes.number.isRequired,
    })),
};

export default DetailsIcon;
