import PropTypes from 'prop-types';

const BasicPremiumAmount = ({
    amount,
    withCurrency,
    currency,
}) => (new Intl.NumberFormat('bg-BG', withCurrency ? {
    currency: currency,
    style: 'currency',
} : {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
})).format(amount);

BasicPremiumAmount.defaultProps = {
    withCurrency: true,
    currency: 'BGN',
};

BasicPremiumAmount.propTypes = {
    amount: PropTypes.number.isRequired,
    withCurrency: PropTypes.bool,
};

export default BasicPremiumAmount;
