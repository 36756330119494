import React, {useCallback} from 'react';
import {Col, Row, Table} from 'react-bootstrap';
import PremiumAmount from '../../elements/Values/PremiumAmount';
import {format} from 'date-fns';
import DateShort from '../../elements/DateTime/DateShort';
import PremiumAmountSum from '../../elements/Values/PremiumAmountSum';
import PropTypes from 'prop-types';
import Card from '../../elements/ReactBootstrap/Card';
import InfoIcon from '../../elements/Icons/InfoIcon';
import DetailsIcon from '../../elements/Icons/DetailsIcon';
import PolicyInfoButton from '../NavigationButtons/PolicyInfoButton';
import StickerInfoButton from '../NavigationButtons/StickerInfoButton';
import GreenCardInfoButton from '../NavigationButtons/GreenCardInfoButton';
import PaymentsCount from '../../elements/Values/PaymentsCount';
import RequireRole from '../../elements/AccessControl/RequireRole';
import {formatGreenCardNumber, formatStickerNumber, paymentMethodsShort, sum} from '../../../utils';
import {useGetCurrentUserQuery} from '../../../features/apiSlice';
import useBrokerVoucherCommission from '../../../hooks/useBrokerVoucherCommission.js';
import Percent from '../../elements/Values/Percent.jsx';
import SmallSpinner from '../../elements/Spinner/SmallSpinner.jsx';
import ExportToExcelButton from "../../elements/Buttons/ExportToExcelButton.jsx";

const ReportTableWidget = ({
    data,
    group,
    headerText,
    isLoading,
    actionButton,
    showTable,
    detailed,
}) => {
    const getFirstInstallment = useCallback(voucher => voucher.installments.concat().shift(), []);
    const getPolicy = useCallback(voucher => getFirstInstallment(voucher).policy, [getFirstInstallment]);
    const getInstallmentNumbers = useCallback(
        voucher => voucher.installments.map(installment => installment.number).join(','),
        []
    );
    const getCommissionUser = useCallback(
        voucher => voucher.commissionUser ? <>
                {' '}<InfoIcon
                    onClick={() => alert('Комисион се изплаща на консултант:\n' + voucher.commissionUser.shortName)} />
            </> : null,
        []
    );

    const {
        data: currentUser,
    } = useGetCurrentUserQuery();

    const { getBrokerVoucherCommissionCoefficient, getBrokerVoucherCommissionAmount } = useBrokerVoucherCommission(currentUser);

    if (data.length === 0) {
        return <Card>
            Няма данни за избрания период. {isLoading && <SmallSpinner />}
        </Card>;
    }

    const showUser = group !== 'user';
    const showOffice = ['office', 'user'].indexOf(group) === -1;
    const showInsuranceCompany = group !== 'insuranceCompany';

    let tableData = {
        categories: {},
        headerFunction: () => headerText,
        rows: {},
    };

    if (group === 'user') {
        tableData.headerFunction = key => {
            const category = tableData.categories[key];

            if (!category) {
                return '[не е избран консултант]';
            }

            return tableData.categories[key].shortName + (tableData.categories[key].office ?
                (' (офис ' + tableData.categories[key].office.name + ')') :
                ''
            );
        };

        data.forEach(row => {
            const user = row.issueUser;
            const key = user?.id || '';

            tableData.categories[key] = user;
            tableData.rows[key] = [
                ...(tableData.rows[key] || []),
                row,
            ];
        });
    }
    else {
        if (group === 'office') {
            tableData.headerFunction = key => {
                const category = tableData.categories[key];

                if (!category) {
                    return '[не е избран офис]';
                }

                return 'Офис ' + category.name;
            };

            data.forEach(row => {
                const office = row.issueOffice;
                const key = office?.id || '';

                tableData.categories[key] = office;
                tableData.rows[key] = [
                    ...(tableData.rows[key] || []),
                    row,
                ];
            });
        }
        else {
            if (group === 'insuranceCompany') {
                tableData.headerFunction = key => tableData.categories[key].shortName;

                data.forEach(row => {
                    const insuranceCompany = getPolicy(row).insuranceCompany;
                    const key = insuranceCompany.id;

                    tableData.categories[key] = insuranceCompany;
                    tableData.rows[key] = [
                        ...(tableData.rows[key] || []),
                        row,
                    ];
                });
            }
            else {
                tableData.categories = {
                    _default: {
                        name: 'Резултати от търсенето',
                    },
                };
                tableData.rows = {
                    _default: data,
                };
            }
        }
    }

    return Object.entries(tableData.rows).sort(([key1], [key2]) => {
        const label1 = tableData.headerFunction(key1).toLowerCase();
        const label2 = tableData.headerFunction(key2).toLowerCase();
        return label1.localeCompare(label2);
    }).map(([key, data]) => {
        const nonVoidedVouchers = data.filter(voucher => !voucher.void);
        const commissionAmountSum = sum(nonVoidedVouchers.map(voucher =>
            getBrokerVoucherCommissionAmount(voucher, getPolicy(voucher))
        ));

        const dataForExport = showTable ? data.map(row => ({
            "Падеж": format(new Date(row.issueDate), 'dd.MM.yyyy'),
            "Консултант": row.issueUser?.shortName || getCommissionUser(row),
            "Офис":  row.issueOffice?.name || '',
            "ЗК": getPolicy(row).insuranceCompany.shortName,
            "Продукт": getPolicy(row).productName,
            "Вноска": getInstallmentNumbers(row)+ '/' +getPolicy(row).installmentsCount,
            "Полица": getPolicy(row).number,
            "Стикер": row.sticker ? formatStickerNumber(row.sticker.number) : '',
            "СЗК": row.greenCard ? row.greenCard.series + formatGreenCardNumber(row.greenCard.number) : '',
            "Начин на плащане": paymentMethodsShort[row.paymentMethod],
            "Премия": row.premiumAmount || 0,
            "Премия €": row.premiumAmountCurrency || 0,
            "Данък": row.taxAmount || 0,
            "Данък €": row.taxAmountCurrency || 0,
            "ГФ": row.guaranteeFundAmount || 0,
            "ГФ €": row.guaranteeFundAmountCurrency || 0,
            "Такса стикер": row.stickerAmount || 0,
            "Такса стикер €": row.stickerAmountCurrency || 0,
            "Общо": row.totalAmount || 0,
            "Общо €": row.totalAmountCurrency || 0,
            "Корекция": row.correctionAmount || 0,
            "Отчетено" : row.storeDate ? format(new Date(row.storeDate), 'dd.MM.yyyy HH:mm') : 'Не',
        })) : [];

        dataForExport.push({
            "Падеж": '',
            "Консултант": '',
            "Офис": '',
            "ЗК": '',
            "Продукт": '',
            "Вноска": '',
            "Полица": '',
            "Стикер": '',
            "СЗК": '',
            "Начин на плащане": '',
            "Премия": sum(data.map(item => item['premiumAmount'])),
            "Премия €": sum(data.map(item => item['premiumAmountCurrency'])),
            "Данък": sum(data.map(item => item['taxAmount'])),
            "Данък €": sum(data.map(item => item['taxAmountCurrency'])),
            "ГФ": sum(data.map(item => item['guaranteeFundAmount'])),
            "ГФ €": sum(data.map(item => item['guaranteeFundAmountCurrency'])),
            "Такса стикер": sum(data.map(item => item['stickerAmount'])),
            "Такса стикер €": sum(data.map(item => item['stickerAmountCurrency'])),
            "Общо": sum(data.map(item => item['totalAmount'])),
            "Общо €": sum(data.map(item => item['totalAmountCurrency'])),
            "Корекция": sum(data.map(item => item['correctionAmount'])),
            "Отчетено": '',
        })

        return (<Card outline key={key} className="p-0" header={
            <Row>
            <Col className='col-11'>{tableData.headerFunction(key)} - {' '}
            <PaymentsCount count={nonVoidedVouchers.length} /> - {' '}
            <PremiumAmountSum arr={nonVoidedVouchers} field="totalAmount" />
            <RequireRole name="ROLE_SHOW_COMMISSION">
                {' '}с комисион <PremiumAmount amount={commissionAmountSum} />
            </RequireRole>
                {isLoading && <SmallSpinner />}</Col>
            <Col className='col-1'>
            {showTable && <ExportToExcelButton data={dataForExport} fileName={'report'} />}</Col>
        </Row>}>
            {showTable && <div className="table-responsive">
                <Table striped className="table-sm mb-0">
                    <thead>
                        <tr>
                            {actionButton && <th />}
                            <th>Седм.</th>
                            {showUser && <th>Консултант</th>}
                            {showOffice && <th>Офис</th>}
                            {showInsuranceCompany && <th>ЗК</th>}
                            <th>Продукт</th>
                            <th>Вноска</th>
                            <th>Полица</th>
                            <th>Стикер</th>
                            <th>СЗК</th>
                            {detailed && <>
                                <th className="text-end">Премия</th>
                                <th className="text-end">Данък</th>
                                <th className="text-end">ГФ</th>
                                <th className="text-end">Стикер</th>
                            </>}
                            <th className="text-end">Общо</th>
                            <th className="text-end">Корекция</th>
                            {detailed && <>
                                <RequireRole name="ROLE_SHOW_COMMISSION">
                                    <th className="text-end">Ком.{'\u00A0'}%</th>
                                    <th className="text-end">Ком.{'\u00A0'}лв.</th>
                                </RequireRole>
                                <th>Падеж</th>
                            </>}
                            <th>Платено</th>
                            <th>Начин</th>
                            <th>Отчетено</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(row => <tr key={row.id}
                                                    className={row.void ? 'progress-bar-striped grid-row-danger' : null}>
                            {actionButton && <td>{actionButton(row)}</td>}
                            <td>{format(new Date(row.issueDate), 'II')}</td>
                            {showUser && <td>{row.issueUser?.shortName || ''}{getCommissionUser(row)}</td>}
                            {showOffice && <td>{row.issueOffice?.name || ''}</td>}
                            {showInsuranceCompany && <td>{getPolicy(row).insuranceCompany.shortName}</td>}
                            <td>{getPolicy(row).productName}{' '}<DetailsIcon details={row.details} /></td>
                            <td>{getInstallmentNumbers(row)}/{getPolicy(row).installmentsCount}</td>
                            <td><PolicyInfoButton policy={getPolicy(row)} /></td>
                            <td>{row.sticker && <StickerInfoButton sticker= {row.sticker} />}</td>
                            <td>{row.greenCard &&
                                <GreenCardInfoButton greenCard={row.greenCard} />
                            }</td>
                            {detailed && <>
                                <td className="text-end">
                                    <PremiumAmount amount={row.premiumAmount || 0} />{' '}
                                    (<PremiumAmount amount={row.premiumAmountCurrency || 0} currency='EUR'/>)
                                </td>
                                <td className="text-end">
                                    <PremiumAmount amount={row.taxAmount || 0} />{' '}
                                    (<PremiumAmount amount={row.taxAmountCurrency || 0} currency='EUR'/>)
                                </td>
                                <td className="text-end">
                                    <PremiumAmount amount={row.guaranteeFundAmount || 0} />{' '}
                                    (<PremiumAmount amount={row.guaranteeFundAmountCurrency || 0} currency='EUR'/>)
                                </td>
                                <td className="text-end">
                                    <PremiumAmount amount={row.stickerAmount || 0} />{' '}
                                    (<PremiumAmount amount={row.stickerAmountCurrency || 0} currency='EUR'/>)
                                </td>
                            </>}
                            <td className="text-end">
                                <PremiumAmount amount={row.totalAmount || 0} />{' '}
                                (<PremiumAmount amount={row.totalAmountCurrency || 0} currency='EUR'/>)
                            </td>
                            <td className="text-end"><PremiumAmount amount={row.correctionAmount || 0} /></td>
                            {detailed && <>
                                <RequireRole name="ROLE_SHOW_COMMISSION">
                                    <td className="text-end">
                                        <Percent coefficient={getBrokerVoucherCommissionCoefficient(row, getPolicy(row))} />
                                    </td>
                                    <td className="text-end">
                                        <PremiumAmount amount={getBrokerVoucherCommissionAmount(row, getPolicy(row))} />
                                    </td>
                                </RequireRole>
                                <td><DateShort date={getFirstInstallment(row).dueDate} /></td>
                            </>}
                            <td><DateShort date={row.issueDate} html /></td>
                            <td>{paymentMethodsShort[row.paymentMethod]}</td>
                            <td style={{whiteSpace: 'nowrap'}}>
                                <DateShort date={row.storeDate} html />{' '}
                                {(row.storageUser || row.storageOffice) ? <InfoIcon
                                    onClick={() => alert([row.storageUser?.shortName, row.storageOffice?.name]
                                    .join(' - '))} /> : 'Не'}
                            </td>
                        </tr>)}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colSpan={
                                (actionButton ? 1 : 0) +
                                6 +
                                (showUser ? 1 : 0) +
                                (showOffice ? 1 : 0) +
                                (showInsuranceCompany ? 1 : 0)
                            } className="text-end">Обща сума:</th>
                            {(() => {
                                return (<>
                                    {detailed && <>
                                        <th className="text-end">
                                            <PremiumAmountSum arr={nonVoidedVouchers} field="premiumAmount" />{' '}
                                            (<PremiumAmountSum arr={nonVoidedVouchers} field="premiumAmountCurrency" />)
                                        </th>
                                        <th className="text-end">
                                            <PremiumAmountSum arr={nonVoidedVouchers} field="taxAmount" />{' '}
                                            <PremiumAmountSum arr={nonVoidedVouchers} field="taxAmountCurrency" />
                                        </th>
                                        <th className="text-end">
                                            <PremiumAmountSum arr={nonVoidedVouchers} field="guaranteeFundAmount" />{' '}
                                            <PremiumAmountSum arr={nonVoidedVouchers} field="guaranteeFundAmountCurrency" />
                                        </th>
                                        <th className="text-end">
                                            <PremiumAmountSum arr={nonVoidedVouchers} field="stickerAmount" />{' '}
                                            <PremiumAmountSum arr={nonVoidedVouchers} field="stickerAmountCurrency" />
                                        </th>
                                    </>}
                                    <th className="text-end">
                                        <PremiumAmountSum arr={nonVoidedVouchers} field="totalAmount" />{' '}
                                        <PremiumAmountSum arr={nonVoidedVouchers} field="totalAmountCurrency" />
                                    </th>
                                    {detailed && <>
                                        <RequireRole name="ROLE_SHOW_COMMISSION">
                                            <th />
                                            <th className="text-end"><PremiumAmount amount={commissionAmountSum} /></th>
                                        </RequireRole>
                                    </>}
                                </>);
                            })()}
                            <th colSpan={
                                (detailed ? 1 : 0) +
                                7
                            } />
                        </tr>
                    </tfoot>
                </Table>
            </div>}
        </Card>);
    });
};

ReportTableWidget.defaultProps = {
    group: 'none',
    headerText: 'Резултати от търсенето',
    showTable: true,
    detailed: false,
    isLoading: false,
};

ReportTableWidget.propTypes = {
    data: PropTypes.array.isRequired,
    group: PropTypes.string,
    headerText: PropTypes.string,
    showTable: PropTypes.bool,
    detailed: PropTypes.bool,
    isLoading: PropTypes.bool,
    actionButton: PropTypes.func,
};

export default ReportTableWidget;
