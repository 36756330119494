import React, {useMemo, useState} from 'react';
import Card from '../elements/ReactBootstrap/Card';
import InstallmentsListWidget from '../widgets/InstallmentsListWidget';
import NoPendingInstallmentsWidget from '../widgets/NoPendingInstallmentsWidget';
import InstallmentPaymentWidget from '../widgets/InstallmentPaymentWidget';
import ErrorWidget from '../widgets/ErrorWidget';
import SuccessfulPaymentWidget from '../widgets/SuccessfulPaymentWidget';
import PolicyInfoWidget from '../widgets/Policy/PolicyInfoWidget.jsx';
import {Col, Container, Row} from 'react-bootstrap';
import {
    useCreateVoucherForPaymentMutation,
    useGetPolicyQuery, useListPrecalculateUserCommissionAmountMutation,
    useSendVoucherEmailMutation
} from '../../features/apiSlice';
import {printGreenCard, printVoucher} from '../../print';
import Alert from '../elements/ReactBootstrap/Alert';
import {useNavigate, useParams} from 'react-router-dom';
import GreenCardPrintButton from '../elements/PrintButtons/GreenCardPrintButton';
import VoucherPrintButton from '../elements/PrintButtons/VoucherPrintButton';
import SelectedBso from '../widgets/Mtpl/SelectedBso';
import PolicySearchWidget from '../widgets/Policy/PolicySearchWidget.jsx';
import {sum} from '../../utils';
import NoGreenCardWarning from '../widgets/Mtpl/NoGreenCardWarning';
import Content from '../partials/Content.jsx';
import VoucherEmailButton from '../elements/Buttons/VoucherEmailButton.jsx';
import SmallSpinner from '../elements/Spinner/SmallSpinner.jsx';
import SlowSpinner from '../elements/Spinner/SlowSpinner.jsx';
import BankAccountInfo from '../elements/BankAccountInfo.jsx';
import BasicPremiumAmount from "../elements/Values/BasicPremiumAmount.jsx";

const InstallmentPayment = () => {
    const [voucherPrinting, setVoucherPrinting] = useState(false);
    const [greenCardPrinting, setGreenCardPrinting] = useState(false);

    const printing = voucherPrinting || greenCardPrinting;

    const [sending, setSending] = useState(false);

    const {policyId} = useParams();
    const navigate = useNavigate();

    const {
        data: policy,
        error: policyError,
        isLoading: policyLoading,
        isSuccess: policySuccess,
        isError: policyIsError,
    } = useGetPolicyQuery(policyId);

    const [pay, {
        data: voucher,
        error: payError,
        isLoading: payLoading,
        isSuccess: paySuccess,
        isError: payIsError,
    }] = useCreateVoucherForPaymentMutation();

    const [send, {
        isSuccess: sendSuccess,
        isError: sendIsError,
        error: sendError,
    }] = useSendVoucherEmailMutation();

    const [userCommissionAmounts] = useListPrecalculateUserCommissionAmountMutation();

    const [selectedInstallments, setSelectedInstallments] = useState([]);

    const isMtpl = useMemo(
        () => policySuccess && policy.product.hasMtplCover,
        [policySuccess, policy]
    );

    const allInstallmentsPaid = useMemo(() => policySuccess && policy.installments.filter(
            installment => installment.paidPremiumFromClient
        ).length === policy.installmentsCount,
        [policySuccess, policy]
    );

    const hasUnpayable = useMemo(() => policySuccess && policy.installments.filter(installment =>
        !installment.paidPremiumFromClient && !installment.payable).length > 0,
        [policySuccess, policy]
    );

    const firstInstallmentPaid = useMemo(() => policySuccess && policy.installments.filter(installment =>
        installment.number === 1 && installment.paidPremiumFromClient
    ).length > 0, [policySuccess, policy]);

    const hasSelectedInstallments = selectedInstallments.length > 0;

    const pendingAmount = useMemo(
        () => sum(selectedInstallments.map(installment => installment.dueAmount)),
        [selectedInstallments]
    );

    const pendingAmountCurrency = useMemo(
        () => sum(selectedInstallments.map(installment => installment.dueAmountCurrency)),
        [selectedInstallments]
    );

    const needsBso = useMemo(
        () => isMtpl && selectedInstallments.filter(installment => installment.number === 1).length === 0,
        [isMtpl, selectedInstallments]
    );

    const descriptionText = useMemo(
        () => policySuccess ? ('вноск' + (selectedInstallments.length > 1 ? 'и' : 'а') + ' ' +
        selectedInstallments.map(installment => installment.number).join(', ') + ' от ' + policy.installmentsCount) : '',
        [policySuccess, selectedInstallments, policy]
    );

    return (<Content title="Плащане на поредна вноска">
        <Row className="row-cols-1 row-cols-xl-2">
            <Col>
                <Card outline>
                    <PolicySearchWidget formName="policySearchInstallment"
                    onSubmit={() => {
                        navigate('/installment-payment/find');
                    }} />
                </Card>

                {policyLoading && <Card>Зареждане на полица... <SmallSpinner /></Card>}

                {policyIsError && <ErrorWidget error={policyError} />}

                {policySuccess && <>
                    <Card noPadding>
                        <InstallmentsListWidget product={policy.product} installments={policy.installments}
                            disabled={payLoading} readOnly={paySuccess}
                            onSelectedInstallmentsChange={setSelectedInstallments} />
                    </Card>
                    {hasSelectedInstallments && <>
                        {isMtpl && policy.insuranceCompany.number === 5 &&
                            <Alert variant="danger" heading="Важно!">
                                Преди плащането задължително проверете МПС -то за коректно изписани<br/>
                                Марка и Модел на латиница, както и правилно избран тип на МПС.
                            </Alert>}
                        {firstInstallmentPaid && hasUnpayable && <Alert variant="warning"
                                heading="Плащане на няколко вноски наведнъж">
                            Застрахователната компания не предлага плащане на повече от една вноска чрез услугите за
                            интеграция. Ако клиентът желае да плати повече от една предстояща вноска, моля издайте ги с
                            отделни бланки, или ползвайте портала на застрахователната компания.
                        </Alert>}
                    </>}
                </>}

                {!paySuccess && <>
                    {allInstallmentsPaid && <NoPendingInstallmentsWidget offerVehicle={policy.insuredVehicle}
                        offerClient={policy.policyHolder || policy.insuredClients?.[0]?.insuredClient} />}

                    {hasSelectedInstallments &&
                    <Card>
                        <InstallmentPaymentWidget totalAmount={pendingAmount} policyNumber={policy.number}
                                                  totalAmountCurrency={pendingAmountCurrency}
                                bsoInsuranceCompanyId={needsBso ? policy.insuranceCompany.id : null}
                        onSubmit={async values => {
                            const userCommissions = await  userCommissionAmounts(
                                selectedInstallments.map(installment => installment.id)
                            ).unwrap();

                            const msg = 'Плащане на '+ descriptionText + ' на стойност ' + BasicPremiumAmount({
                                amount: pendingAmount,
                                withCurrency: BasicPremiumAmount.defaultProps.withCurrency, // manually calling function
                                    currency: 'BGN',
                                }) + '(' + BasicPremiumAmount({
                                    amount: pendingAmountCurrency,
                                    withCurrency: BasicPremiumAmount.defaultProps.withCurrency, // manually calling function
                                    currency: 'EUR',
                                }) + ')' +
                                + ' ? Очакван комисион ' + Object.entries(userCommissions).map(([productType, amount]) =>
                                productType + ' : ' + (!isNaN(amount)) ?
                                    BasicPremiumAmount({
                                        amount: amount,
                                        withCurrency: BasicPremiumAmount.defaultProps.withCurrency, // manually calling function
                                        currency: 'BGN',
                                    }) : amount
                                    ).join(', ');

                            // TODO change with react-bootstrap modal
                            if (!window.confirm(msg)) {
                                throw new Error('Плащането беше отказано');
                            }

                            const voucher = await pay({
                                installments: selectedInstallments.map(installment => installment.id),
                                ...values,
                            }).unwrap();

                            setVoucherPrinting(true);
                            await printVoucher(voucher.id);
                            setVoucherPrinting(false);

                            if (voucher.greenCard) {
                                setGreenCardPrinting(true);
                                await printGreenCard(voucher.greenCard.id);
                                setGreenCardPrinting(false);
                            }
                        }} />
                    </Card>}
                </>}

                {payLoading && <Alert variant="secondary" icon={null} heading={<>
                    <span className="me-2">
                        <SlowSpinner />
                    </span>
                    Моля, изчакайте...
                </>}>
                    Издаването на сметка и СЗК може да отнеме от 20 секунди до 2 минути.<br/>
                    Моля, не натискайте други бутони и не затваряйте тази страница, докато не приключи!
                </Alert>}

                {paySuccess && <>
                    <SuccessfulPaymentWidget amount={voucher.totalAmount} amountCurrency={voucher.totalAmountCurrency}/>
                    {isMtpl && <SelectedBso voucher={voucher} />}
                    {isMtpl && voucher.sticker && !voucher.greenCard && <NoGreenCardWarning />}
                    {voucher.paymentMethod === 'BANK_ACCOUNT_BROKER' && <Card header="Данни за банков превод">
                        <BankAccountInfo totalAmount={voucher.totalAmount}
                                         totalAmountCurrency={voucher.totalAmountCurrency}
                                         reason={policy.number}
                        />
                    </Card>}
                    <Container as={Card}>
                        <Row className="row-cols-1 row-cols-md-2 row-cols-lg-1 row-cols-xl-2 g-3">
                            <Col>
                                <VoucherPrintButton voucher={voucher} printing={voucherPrinting}
                                                    disabled={printing} setDisabled={setVoucherPrinting} />
                            </Col>
                            <Col>{voucher.greenCard &&
                                <GreenCardPrintButton greenCard={voucher.greenCard} printing={greenCardPrinting}
                                                      disabled={printing} setDisabled={setGreenCardPrinting} />}
                            </Col>
                            <Col>
                                <VoucherEmailButton onClick={() => send(voucher.id)} disabled={sending}
                                    setDisabled={setSending} />
                            </Col>
                        </Row>
                    </Container>
                    {sendSuccess && <Alert variant="success" heading="Документите бяха изпратени успешно" />}
                    {sendIsError && <ErrorWidget error={sendError} />}
                </>}

                {payIsError && <ErrorWidget error={payError} />}
            </Col>
            <Col>
                {policySuccess && <PolicyInfoWidget policy={policy} header="Информация за полицата" />}
            </Col>
        </Row>
    </Content>);
};

export default InstallmentPayment;
