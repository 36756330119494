import React, {useCallback, useState} from 'react';
import {Table} from 'react-bootstrap';
import DateShort from '../elements/DateTime/DateShort';
import PremiumAmount from '../elements/Values/PremiumAmount';
import DetailsIcon from '../elements/Icons/DetailsIcon';
import VoucherPrintButton from '../elements/PrintButtons/VoucherPrintButton';
import GreenCardPrintButton from '../elements/PrintButtons/GreenCardPrintButton';
import PropTypes from 'prop-types';
import StickerInfoButton from './NavigationButtons/StickerInfoButton';
import GreenCardInfoButton from './NavigationButtons/GreenCardInfoButton';
import {useGetCurrentUserQuery, useSendVoucherEmailMutation, useUpdateVoucherMutation} from '../../features/apiSlice';
import ErrorWidget from './ErrorWidget';
import RequireRole from '../elements/AccessControl/RequireRole';
import SwitchButton from '../elements/Form/SwitchButton';
import {paymentMethods} from '../../utils.js';
import VoucherEmailButton from '../elements/Buttons/VoucherEmailButton.jsx';
import useBrokerVoucherCommission from '../../hooks/useBrokerVoucherCommission.js';
import Percent from '../elements/Values/Percent.jsx';
import AgentCommissionListWidget from './AgentCommissionListWidget.jsx';
import TextWithCopyButton from './TextWithCopyButton.jsx';

const VouchersListWidget = ({
                                policy,
                                vouchers,
                                printing,
                                setPrinting,
                            }) => {
    const [sending, setSending] = useState(false);

    const [send] = useSendVoucherEmailMutation();

    const [update, {
        isError: updateIsError,
        error: updateError,
    }] = useUpdateVoucherMutation();

    const getFirstInstallment = useCallback(voucher => voucher.installments.concat().shift(), []);
    const getPolicy = useCallback(voucher => getFirstInstallment(voucher).policy, [getFirstInstallment]);

    const {
        data: currentUser,
    } = useGetCurrentUserQuery();

    const {
        getBrokerVoucherCommissionCoefficient,
        getBrokerVoucherCommissionAmount,
    } = useBrokerVoucherCommission(currentUser);

    if (!policy) {
        return null;
    }

    const hasTax = policy.product.insuranceType !== 'life';
    const hasMtplCover = policy.product.hasMtplCover;
    const hasGf = hasMtplCover || policy.product.hasPassengersAccidentCover;

    return (<div className="table-responsive">
        <Table striped className="mb-0">
            <thead>
            <tr>
                <th>№</th>
                <th>Дата</th>
                <th>Вноска</th>
                <th>Премия</th>
                {hasTax && <th>Данък</th>}
                {hasMtplCover && <th>Стикер</th>}
                {hasGf && <th>ГФ/ОФ</th>}
                <th>Сума</th>
                <th colSpan={2}>Печат</th>
                <th>Мейл</th>
            </tr>
            </thead>
            <tbody>
            {vouchers.map(voucher => <React.Fragment key={voucher.id}>
                <tr className={voucher.void ? 'progress-bar-striped grid-row-danger' : null}>
                    <td>
                        <TextWithCopyButton text={voucher.number}/>
                    </td>
                    <td>
                        <DateShort date={voucher.issueDate} html/>
                    </td>
                    <td>
                        {(voucher.installments || []).map(installment => installment.number).join(',')} /{' '}
                        {getPolicy(voucher).installmentsCount || ''}
                    </td>
                    <td>
                        <PremiumAmount amount={voucher.premiumAmount}/>{' '}
                        (<PremiumAmount amount={voucher.premiumAmountCurrency} currency='EUR'/>)
                    </td>
                    {hasTax && <td>
                        <PremiumAmount amount={voucher.taxAmount}/>{' '}
                        (<PremiumAmount amount={voucher.taxAmountCurrency} currency='EUR'/>)
                    </td>}
                    {hasMtplCover && <>
                        <td>
                            <PremiumAmount amount={voucher.stickerAmount}/>{' '}
                            (<PremiumAmount amount={voucher.stickerAmountCurrency} currency='EUR'/>)
                        </td>
                    </>}
                    {hasGf && <>
                        <td>
                            <PremiumAmount amount={voucher.guaranteeFundAmount}/>{' '}
                            (<PremiumAmount amount={voucher.guaranteeFundAmountCurrency} currency='EUR'/>)
                        </td>
                    </>}
                    <td style={{whiteSpace: 'nowrap'}}>
                        <PremiumAmount amount={voucher.totalAmount}/>{' '}
                        (<PremiumAmount amount={voucher.totalAmountCurrency} currency='EUR'/>){' '}
                        <DetailsIcon details={voucher.details}/>
                    </td>
                    <td>
                        <VoucherPrintButton voucher={voucher} label="" printing={false}
                                            disabled={printing} setDisabled={setPrinting}/>
                    </td>
                    <td>
                        {voucher.greenCard && <GreenCardPrintButton greenCard={voucher.greenCard}
                                                                    label="" printing={false} disabled={printing}
                                                                    setDisabled={setPrinting}/>}
                    </td>
                    <td>
                        <VoucherEmailButton onClick={() => send(voucher.id)} disabled={sending}
                                            setDisabled={setSending} label=""/>
                    </td>
                </tr>
                <tr/>
                {/* even-odd fix */}
                <tr className={voucher.void ? 'progress-bar-striped grid-row-danger' : null}>
                    <td colSpan={11}>
                        {voucher.sticker && <div>
                            Стикер: <StickerInfoButton sticker={voucher.sticker}/>
                        </div>}
                        {voucher.greenCard && <div>
                            СЗК: <GreenCardInfoButton greenCard={voucher.greenCard}/>
                            {voucher.greenCard.policyNumber && <>{' '}
                                <GreenCardInfoButton greenCard={voucher.greenCard} textField="policyNumber"/>
                            </>}
                        </div>}
                        {voucher.issueUser ?
                            <div>
                                Консултант: {voucher.issueUser?.shortName} (офис {voucher.issueOffice?.name})
                            </div> :
                            <div>{voucher.issueInsuranceBroker ?
                                <>Брокер: {voucher.issueInsuranceBroker?.shortName}</> :
                                'Не е попълнен брокер'
                            }</div>
                        }
                        <div>
                            Начин на плащане: {paymentMethods[voucher.paymentMethod]}
                            {voucher.paymentMethod === 'POS_TERMINAL' && voucher.posTerminalReference && <>
                                , код: <TextWithCopyButton text={voucher.posTerminalReference}/>
                            </>}
                        </div>
                        {voucher.commissionUser && <div>
                            Комисион се изплаща на: {voucher.commissionUser.shortName}
                        </div>}
                        <div>
                            Показване в предстоящи плащания: <SwitchButton
                            checked={voucher.notifyUser}
                            onChange={checked => update([voucher.id, {
                                notifyUser: checked,
                            }])}
                        />
                        </div>
                        {voucher.premiumAmount > 0 && <RequireRole name="ROLE_SHOW_COMMISSION">
                            <div>
                                Комисион брокер:{' '}
                                <PremiumAmount amount={getBrokerVoucherCommissionAmount(voucher, policy)}/>{' '}
                                (<Percent coefficient={getBrokerVoucherCommissionCoefficient(voucher, policy)}/>)
                            </div>
                            {(voucher.issueUser || policy.issueUser) &&
                            <AgentCommissionListWidget voucher={voucher}/>}
                        </RequireRole>}
                        <RequireRole name="ROLE_UPDATE_VOUCHER">
                            Анулирана: <SwitchButton
                            checked={voucher.void}
                            onChange={checked => update([voucher.id, {
                                void: checked,
                            }])}
                            invertColors={true}
                        />
                        </RequireRole>
                        {updateIsError && <div className="mt-3">
                            <ErrorWidget error={updateError}/>
                        </div>}
                    </td>
                </tr>
            </React.Fragment>)}
            </tbody>
        </Table>
    </div>);
};

VouchersListWidget.propTypes = {
    policy: PropTypes.shape({
        product: PropTypes.shape({
            insuranceType: PropTypes.string.isRequired,
            hasMtplCover: PropTypes.bool.isRequired,
            hasPassengersAccidentCover: PropTypes.bool.isRequired,
        }).isRequired,
    }),
    vouchers: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        number: PropTypes.string,
        issueDate: PropTypes.string,
        premiumAmount: PropTypes.number.isRequired,
        taxAmount: PropTypes.number.isRequired,
        stickerAmount: PropTypes.number.isRequired,
        guaranteeFundAmount: PropTypes.number.isRequired,
        totalAmount: PropTypes.number.isRequired,
        greenCard: PropTypes.object,
    })).isRequired,
};

export default VouchersListWidget;
