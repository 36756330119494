import React from 'react';
import PropTypes from 'prop-types';
import Alert from '../elements/ReactBootstrap/Alert';
import PremiumAmount from "../elements/Values/PremiumAmount";

const SuccessfulPaymentWidget = ({
    amount,
    amountCurrency,
}) => {
    return (<Alert variant="success" heading={<>Плащането за
        <PremiumAmount amount={amount} />{' '}(<PremiumAmount amount={amountCurrency} currency='EUR'/>)
        е успешно!</>} />);
};

SuccessfulPaymentWidget.propTypes = {
    amount: PropTypes.number.isRequired,
};

export default SuccessfulPaymentWidget;
