import React from 'react';
import PremiumAmount from './Values/PremiumAmount.jsx';
import PropTypes from 'prop-types';
import {useGetCurrentUserQuery} from '../../features/apiSlice.js';
import SmallSpinner from './Spinner/SmallSpinner.jsx';

const BankAccountInfo = ({
    totalAmount,
    totalAmountCurrency,
    reason,
}) => {
    const {
        data: user,
        isLoading: userIsLoading,
        isSuccess: userSuccess,
    } = useGetCurrentUserQuery();

    return (<>
        <div>
            IBAN: {userIsLoading && <SmallSpinner />}
            {userSuccess && <strong>{user.insuranceBroker.iban}</strong>}
        </div>
        <div>
            BIC/SWIFT: {userIsLoading && <SmallSpinner />}
            {userSuccess && <strong>{user.insuranceBroker.bic}</strong>}
        </div>
        <div>
            Получател: {userIsLoading && <SmallSpinner />}
            {userSuccess && <strong>{user.insuranceBroker.name}</strong>}
        </div>
        <div>
            Основание: {reason ? <strong>{reason}</strong> : <em>номер на полицата - визуализира се след издаване</em>}
        </div>
        <div>
            Сума: <strong>
            <PremiumAmount amount={totalAmount} />
            (<PremiumAmount amount={totalAmountCurrency} currency={'EUR'}/>)
        </strong>
        </div>
    </>);
};

BankAccountInfo.propTypes = {
    totalAmount: PropTypes.number.isRequired,
    reason: PropTypes.string,
};

export default BankAccountInfo;
